import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import MasterLayout from '../components/MasterLayout/MasterLayout';
import PageTitle from './components/PageTitle';

const Template = ({
  data, // this prop will be injected by the GraphQL query below.
  location
}) => {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;

  return (
    <MasterLayout location={location}>
      <div className="post-container">
        <div className="post">
          <PageTitle>{frontmatter.title}</PageTitle>

          <div className="post-content-wrapper">
            {!frontmatter.hideTitle && <h1>{frontmatter.title}</h1>}

            <div
              className="post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
      </div>
    </MasterLayout>
  );
};

Template.propTypes = {
  data: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        hideTitle
      }
    }
  }
`;

export default Template;
